// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttons_KrIPd,.colorPicker_une3k{display:flex;flex-direction:row}.colorPicker_une3k{flex-wrap:wrap;width:100%}.color_MCsVC{border-radius:50%;cursor:pointer;height:2rem;margin:.5rem;transition:all var(--transition-duration-short) ease-in-out;width:2rem}.active_BAnB7{transform:scale(1.25)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "buttons_KrIPd",
	"colorPicker": "colorPicker_une3k",
	"color": "color_MCsVC",
	"active": "active_BAnB7"
};
module.exports = ___CSS_LOADER_EXPORT___;
